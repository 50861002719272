import React, { Component, Fragment, lazy, Suspense } from 'react';
import './App.css';
import { AxlLoading } from 'axl-reactjs-ui';
import { Provider, Observer } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import { datadogRum } from '@datadog/browser-rum';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import MainMenu from './MainMenu';
import Header from 'containers/Header';
import MainMenuWrapper from './MainMenuWrapper';
import Firebase, { FirebaseContext } from './components/Firebase';
import RolesAuthRoute from './components/RolesAuthRoute';
import AxlLeftSideMenu from './components/AxlLeftSideMenu';
import RequestErrorHandlerManager from './containers/RequestHandlerManager';

const ToastScreen = lazy(() => import('screens/Toast'));
const DispatchScreen = lazy(() => import('screens/Dispatch'));
const AssignmentDetailScreen = lazy(() => import('screens/AssignmentDetail'));
const SearchScreen = lazy(() => import('screens/Search'));
const ScheduleScreen = lazy(() => import('screens/Schedule'));
const DriverRegistrationScreen = lazy(() => import('screens/DriverRegistration'));
const DriverListScreen = lazy(() => import('screens/Drivers'));
const DriverPoolScreen = lazy(() => import('screens/DriverPool'));
const DriverCrewScreen = lazy(() => import('screens/DriverCrew'));
const DashboardScreen = lazy(() => import('screens/Dashboard'));
const DriverPickupScreen = lazy(() => import('screens/DriverPickupTime'));
const CallCenterScreen = lazy(() => import('screens/CallCenter'));
const GeocodeAddressScreen = lazy(() => import('screens/GeocodeAddress'));
const DriverAnnouncementScreen = lazy(() => import('screens/DriverAnnouncement'));
const DriverSuspensionScreen = lazy(() => import('screens/DriverSuspension'));
const ClientScreen = lazy(() => import('screens/Client'));
const MessengerScreen = lazy(() => import('screens/Messenger'));
const TicketBookingScreen = lazy(() => import('screens/TicketBooking'));
const TicketBookingSessionScreen = lazy(() => import('screens/TicketBookingSession'));
const DriverRenewalScreen = lazy(() => import('screens/DriverRenewal'));
const AddRedelivery = lazy(() => import('screens/AddRedelivery'));
const ShipmentScreen = lazy(() => import('screens/Shipment'));
const DriverDetailScreen = lazy(() => import('screens/DriverDetailScreen'));
const LinehaulDashboard = lazy(() => import('screens/LHDashboard'));
const AddRedeliveryV2 = lazy(() => import('screens/AddRedeliveryV2'));
const Frankenroute = lazy(() => import('screens/Frankenroute'));
const DriverRatingConfig = lazy(() => import('./containers/DriverRatingConfig'));
const CallCenterAbandondedScreen = lazy(() => import('./containers/CallCenterAbandoned'));

import { stores } from './stores';
import { lightTheme } from './themes';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import 'react-toastify/dist/ReactToastify.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import 'flatpickr/dist/themes/material_green.css';

const LoadingIndicator = () => {
  return (
    <div className="loading">
      <AxlLoading color="#FFF" size={75} thin={1} />
    </div>
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      firebase: new Firebase(),
      loading: false,
    };
  }

  async componentDidMount() {
    const { firebase } = this.state;
    this.setState({ loading: true });
    await stores.userStore.getUser();
    await stores.clientStore.getActiveClients();
    await stores.permissionStore.getPermissionSetting();
    await firebase.initFirebaseMessage();

    if (datadogRum && stores.userStore && stores.userStore.user) {
      datadogRum.setUser({
        id: stores.userStore.user.id,
        name: stores.userStore.user.name,
        scopes: stores.userStore.user.scopes,
      });
    }
    this.setState({ loading: false });
  }

  toogleMenuSlider = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  closeMenuSlider = () => {
    this.setState({ isActive: false });
  };

  logout() {
    stores?.sensitiveInfoStore?.resetData();
    window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?logout=true&next=${encodeURI(window.location.href)}`;
  }

  render() {
    const { isActive, firebase, loading } = this.state;
    const { isFetchedUser, user } = stores.userStore;
    const DEFINED = {
      UNKNOWN_DRIVER: 1,
      UNKNOWN_RECIPIENT: 2,
      DRIVER: 8,
      RECIPIENT: 9,
    };
    const urlParams = new URLSearchParams(window.location.search);
    const callArgs = callArgsHandle(urlParams.get('call_args')) || [9];

    function callArgsHandle(value) {
      if (!value) return null;

      return value.split('|').map((a) => parseInt(a));
    }

    if (loading || !isFetchedUser || !user) return <LoadingIndicator />;

    return (
      <ThemeProvider theme={lightTheme}>
        <Provider {...stores}>
          <FirebaseContext.Provider value={firebase}>
            <Suspense fallback={<LoadingIndicator />}>
              <Router>
                <div className="App">
                  <Observer
                    render={() =>
                      stores.lnfStore.fullscreen ? (
                        <Fragment />
                      ) : (
                        <Fragment>
                          <Header onClick={this.toogleMenuSlider} logout={this.logout} />
                          <MainMenu />
                        </Fragment>
                      )
                    }
                  />
                  <MainMenuWrapper stores={stores} />
                  <Observer
                    render={() => (
                      <div
                        onClick={() => stores.lnfStore.toggleFullScreen()}
                        style={{
                          width: '32px',
                          height: '32px',
                          borderRadius: '16px',
                          position: 'absolute',
                          top: stores.lnfStore.fullscreen ? '2px' : '60px',
                          right: '8px',
                          opacity: stores.lnfStore.fullscreen ? 0.2 : 0.05,
                          backgroundColor: 'white',
                          zIndex: 1000,
                          cursor: 'pointer',
                        }}
                      >
                        <i className={'fa fa-arrows'} style={{ fontSize: '16px', padding: '8px' }} />
                      </div>
                    )}
                  />
                  <div className="main-content">
                    <Switch>
                      <Route path="/linehauls" component={LinehaulDashboard} />
                      <Route path="/dashboard" component={DashboardScreen} />
                      <Route path="/driver-registration" component={DriverRegistrationScreen} />
                      <Route path="/drivers/:id" component={DriverDetailScreen} />
                      <Route path="/drivers" component={DriverListScreen} />
                      <Route path="/driver-crews" component={DriverCrewScreen} />
                      <Route path="/driver-pools" component={DriverPoolScreen} />
                      <Route path="/driver-announcements" component={DriverAnnouncementScreen} />
                      <Route path="/driver-probations" component={DriverSuspensionScreen} />
                      <Route path="/driver-renewal" component={DriverRenewalScreen} />
                      <Route path="/toast" component={ToastScreen} />
                      <Route path="/clients" component={ClientScreen} />
                      <RolesAuthRoute path="/geocode-addresses" roles={['super-admin']}>
                        <GeocodeAddressScreen />
                      </RolesAuthRoute>
                      <Route path="/search" component={SearchScreen} />
                      <Route path="/schedule" component={ScheduleScreen} />
                      <Route path="/assignments/:assignmentId" component={AssignmentDetailScreen} />
                      <Route path="/assignments" component={DashboardScreen} />
                      <Route
                        path="/routes/:day/:region/:client/:assignmentId/stops/:stopId"
                        component={DispatchScreen}
                      />
                      <Route path="/routes/:day/:region/:client/:assignmentId" component={DispatchScreen} />
                      <Route path="/routes/:day/:region/:client" component={DispatchScreen} />
                      <Route path="/routes/:day/:region" component={DispatchScreen} />
                      <Route path="/routes/:day" component={DispatchScreen} />
                      <Route path="/routes" component={DispatchScreen} />
                      <Route path="/driver-pickup" component={DriverPickupScreen} />
                      <Route path="/messenger/:id" component={MessengerScreen} />
                      <Route path="/messenger" component={MessengerScreen} />
                      <Route
                        path="/call-center/results"
                        render={() => {
                          if ([DEFINED.UNKNOWN_RECIPIENT, DEFINED.RECIPIENT].indexOf(callArgs[0]) !== -1) {
                            return <RedirectCallCenter />;
                          } else {
                            return <CallCenterScreen />;
                          }
                        }}
                      />
                      <Route path="/ticket-booking" exact={true} component={TicketBookingScreen} />
                      <Route path="/ticket-booking/:id" component={TicketBookingSessionScreen} />
                      <Route path="/abandoned-calls" component={CallCenterAbandondedScreen} />
                      <Route path="/redelivery/multi/v2" component={AddRedeliveryV2} />
                      <Route path="/redelivery/multi" component={AddRedelivery} />
                      <Route path="/shipments/:id" component={ShipmentScreen} />
                      <Route path={['/frankenroute/:id', '/frankenroute']} component={Frankenroute} />
                      <Route path="/driver-rating-config" component={DriverRatingConfig} />
                      <Route path="/" component={DispatchScreen} />
                    </Switch>
                  </div>
                  <AxlLeftSideMenu isActive={isActive} onClose={this.closeMenuSlider} />
                </div>
              </Router>
            </Suspense>
            <ToastContainer enableMultiContainer containerId="main" />
            <RequestErrorHandlerManager />
          </FirebaseContext.Provider>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;

class RedirectCallCenter extends React.PureComponent {
  render() {
    window.location.href = `${process.env.REACT_APP_SUPPORT_URL}/call-center/results${window.location.search}`;
    return <div>Redirecting...</div>;
  }
}
