import _ from 'lodash';
import { observable, action, makeObservable } from 'mobx';

export const JT_REVEAL_LAST_TIME = 'jt_reveal_last_time'
export const JT_REVEAL_REASON = 'jt_reveal_reason'

class SensitiveInfoStore {
  constructor(api) {
    makeObservable(this, {
      data: observable,
      revealReasonTime: observable,
      setRevealLatestTime: action,
      getRevealLatestTime: action,
      setReason: action,
      getReason: action,
      setData: action,
      setRevealReasonTime: action,
      resetData: action,
    });
    this.api = api;
  }

  data = {};
  revealReasonTime = null;

  /**
   * @param {*} uid: DR_001, US_001 ...
   */
  getSensitiveInfo = (uid, field, reason, reasonCode, extraParams) => {
    const fields = [field]
    const params = { 'extra_params': extraParams,
                    fields, 
                    reason,
                    source: "dispatcher_app"
                  }
    return this.api.post(`/sensitive-info/${uid}/reveal`, params);
  }

  setRevealLatestTime = (time) => {
    localStorage.setItem(JT_REVEAL_LAST_TIME, time);
  }

  setReason = (text) => {
    localStorage.setItem(JT_REVEAL_REASON, text);
  }

  setData = (data) => {
    this.data = data
  }

  setRevealReasonTime = (minutes) => {
    this.revealReasonTime = minutes
  }

  resetData = () => {
    localStorage.setItem(JT_REVEAL_REASON, '');
    localStorage.setItem(JT_REVEAL_LAST_TIME, null);
    this.data = {}
  }

  getRevealLatestTime = () => {
    return localStorage.getItem(JT_REVEAL_LAST_TIME);
  }

  getReason = () => {
    return localStorage.getItem(JT_REVEAL_REASON);
  }
}

export default SensitiveInfoStore;
